import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavLink,
  NavItem,
  NavbarText,
  DropdownItem,
  UncontrolledDropdown,
  NavbarToggler,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import Logo from '../Logo.png';
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import carliapp from '../pdf/carliapp.pdf';

function App() {
  return (
    <div className="bgimg">
      <Header />
      {/* <iframe
        src={`${carliapp}#view=fitH&toolbar=0`}
        title="testPdf"
        height="100%"
        width="100%"
      /> */}

      <embed
        src={`${carliapp}#toolbar=0&view=fitH`}
        type="application/pdf"
        width="100%"
        height="100%"
      />

      {/* <div className="middle">
        <h1>COMING SOON</h1>
        <hr />
      </div> */}
    </div>
  );
}

export default App;
