import {
	Navbar,
	NavbarBrand,
	Collapse,
	Nav,
	NavLink,
	NavItem,
	NavbarText,
	DropdownItem,
	UncontrolledDropdown,
	NavbarToggler,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import Logo from '../Logo.png';
import newlogo from '../assets/newlogo.png';
import React, { useState, useEffect } from 'react';

function Header() {
	let [collapsed, setCollapsed] = useState(false);

	const toggle = () => {
		setCollapsed(!collapsed);
	};
	return (
		<div>
			<Navbar expand='md'>
				<NavbarBrand href='/'>
					<img className='main_logo' src={newlogo} />
				</NavbarBrand>
				<NavbarToggler onClick={toggle}>
					<i class='fa fa-list'></i>
				</NavbarToggler>
				<Collapse isOpen={collapsed} navbar toggle={toggle}>
					<Nav className='ms-auto' navbar>
						<NavItem>
							<NavLink
								href='https://carli-manager-platform.web.app'
								target='_blank'
							>
								Carli Manager
							</NavLink>
						</NavItem>
						<NavItem className='me-4'>
							<NavLink href='/privacy'>Privacy Policy</NavLink>
						</NavItem>
						<NavItem className='me-4'>
							<NavLink href='/terms'>Terms & Conditions</NavLink>
						</NavItem>
						<NavItem className='me-4'>
							<NavLink
								href='mailto:support@thecarliapp.com'
								target='_blank'
							>
								Contact Us
							</NavLink>
						</NavItem>

						<NavItem className='me-4'>
							<NavLink href='/download' target='_blank'>
								Download
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
}

export default Header;
