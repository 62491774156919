import React from 'react';
import Header from '../components/Header';
import { Container } from 'reactstrap';
import qr_code from '../assets/qr_code.png';

function Download() {
	return (
		<>
			<div className='bg_policy'>
				<Header />
				<Container className='mt-3 mb-3 privacy d-flex justify-content-center'>
					<img
						src={qr_code}
						alt=''
						className='img-fluid qr_code_img'
					/>
				</Container>
			</div>
		</>
	);
}

export default Download;
