import React from 'react';
import Header from '../components/Header';
import { Container } from 'reactstrap';

function Privacy() {
  return (
    <>
      <div className="bg_policy">
        <Header />
        <Container className="mt-3 mb-3 privacy">
          <h3 className="fw-bold">PRIVACY POLICY</h3>
          <p>
            We are committed to maintaining the accuracy, confidentiality, and
            security of your personally identifiable information{' '}
            <span className="fw-bold">("Personal Information")</span>. As part
            of this commitment, our privacy policy governs our actions as they
            relate to the collection, process, use and disclosure of Personal
            Information
            <span className="text_with_background">
              {' '}
              and information regarding our use of cookies and similar
              technologies.
            </span>{' '}
          </p>
          <p>
            Our privacy policy is based upon the values set by the Canadian
            Standards Association's Model Code for the Protection of Personal
            Information and Canada's Personal Information Protection and
            Electronic Documents Act.
          </p>

          <p>
            <span className="text_with_background">
              Before accessing or using our service, please ensure that you have
              read and understood our collection, storage, use and disclosure of
              personal information. By accessing or using the Carli Application,
              you are accepting and consenting to the practices described in
              this privacy policy.
            </span>
          </p>
          <h5 className="fw-bold">1. Introduction</h5>

          <p>
            We are responsible for maintaining and protecting the Personal
            Information under our control. We have designated an individual or
            individuals who is/are responsible for compliance with our privacy
            policy.
          </p>

          <h5 className="fw-bold">2. Identifying Purposes</h5>
          <p>
            We collect, use and disclose Personal Information to provide you
            with the service you have requested and to offer you additional
            services we believe you might be interested in. The purposes for
            which we collect Personal Information will be identified before or
            at the time we collect the information. In certain circumstances,
            the purposes for which information is collected may be clear, and
            consent may be implied, such as where your name AND address.
          </p>

          <h5 className="fw-bold">3. Consent</h5>
          <p>
            Knowledge and consent are required for the collection, use or
            disclosure of Personal Information except where required or
            permitted by law. Providing us with your Personal Information is
            always your choice. However, your decision not to provide certain
            information may limit our ability to provide you with our products
            or services. We will not require you to consent to the collection,
            use, or disclosure of information as a condition to the supply of a
            product or service, except as required to be able to supply the
            product or service.
          </p>
          <h5 className="fw-bold">4. Limiting Collection</h5>
          <p>
            The Personal Information collected will be limited to those details
            necessary for the purposes identified by us. With your consent, we
            may collect Personal Information from you in person, over the
            telephone or by corresponding with you via mail, facsimile, or the
            Internet.
          </p>

          <h5 className="fw-bold">5. Limiting Use, Disclosure and Retention</h5>
          <p>
            Personal Information may only be used or disclosed for the purpose
            for which it was collected unless you have otherwise consented, or
            when it is required or permitted by law. Personal Information will
            only be retained for the period of time required to fulfil the
            purpose for which we collected it or as may be required by law.{' '}
          </p>

          <h5 className="fw-bold">6. Accuracy</h5>
          <p>
            Personal Information will be maintained in as accurate, complete and
            up-to-date form as is necessary to fulfil the purposes for which it
            is to be used.
          </p>

          <h5 className="fw-bold">7. Safeguarding Customer Information</h5>
          <p>
            Personal Information will be protected by security safeguards that
            are appropriate to the sensitivity level of the information. We take
            all reasonable precautions to protect your Personal Information from
            any loss or unauthorized use, access or disclosure.
          </p>

          <h5 className="fw-bold">8. Openness</h5>
          <p>
            We will make information available to you about our policies and
            practices with respect to the management of your Personal
            Information.
          </p>

          <h5 className="fw-bold">9. User Access</h5>
          <p>
            Upon request, you will be informed of the existence, use and
            disclosure of your Personal Information, and will be given access to
            it. You may verify the accuracy and completeness of your Personal
            Information, and may request that it be amended, if appropriate.
            However, in certain circumstances permitted by law, we will not
            disclose certain information to you. For example, we may not
            disclose information relating to you if other individuals are
            referenced or if there are legal, security or commercial proprietary
            restrictions.
          </p>

          <h5 className="fw-bold">
            10. Handling User Complaints and Suggestions{' '}
          </h5>
          <p>
            You may direct any questions or enquiries with respect to our
            privacy policy or our practices by contacting us at{' '}
            <a href="#">support@thecarliapp.com</a>
          </p>
          <h5 className="fw-bold">11. Changes to the Privacy Policy</h5>
          <p>
            <span className="text_with_background">
              We may update this privacy statement at our sole discretion. The
              date of the last revision of this policy appears at the end of
              this page. We encourage you to periodically review this page for
              the latest information on our Privacy Policy and practices.
              Regardless of changes to our Privacy Policy, we will never use the
              information you submit under our current privacy notice in a new
              way without first notifying you and giving you the option to stop
              using our service.
            </span>
          </p>
          <h5 className="fw-bold">12. Additional Information</h5>
          <p className="fw-bold ">
            <i>Cookies</i>
          </p>
          <p>
            A cookie is a small computer file or piece of information that may
            be stored in your computer's hard drive when you visit our websites.
            We may use cookies to improve our website’s functionality and, in
            some cases, to provide visitors with a customized online experience.
            Cookies are widely used and most web browsers are configured
            initially to accept cookies automatically. You may change your
            Internet browser settings to prevent your computer from accepting
            cookies or to notify you when you receive a cookie so that you may
            decline its acceptance. Please note, however, if you disable
            cookies, you may not experience optimal performance of our website.
          </p>
          <p className="fw-bold">
            <i>Other Websites</i>
          </p>
          <p>
            Our website may contain links to other third-party sites that are
            not governed by this privacy policy. Although we endeavour to only
            link to sites with high privacy standards, our privacy policy will
            no longer apply once you leave our website. Additionally, we are not
            responsible for the privacy practices employed by third party
            websites. Therefore, we suggest that you examine the privacy
            statements of those sites to learn how your information may be
            collected, used, shared and disclosed.
          </p>
        </Container>
      </div>
    </>
  );
}

export default Privacy;
