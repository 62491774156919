import logo from './logo.svg';
import './App.css';
import {
	Navbar,
	NavbarBrand,
	Collapse,
	Nav,
	NavLink,
	NavItem,
	NavbarText,
	DropdownItem,
	UncontrolledDropdown,
	NavbarToggler,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import Logo from './Logo.png';
import React, { useState, useEffect } from 'react';
import Home from './views/Home';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import Download from './views/Download';

function App() {
	return (
		<div>
			<BrowserRouter>
				<Switch>
					<Route exact path='/' component={Home} />
					<Route path='/privacy' component={Privacy} />
					<Route path='/terms' component={Terms} />
					<Route path='/download' component={Download} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
