import React from 'react';
import { Container } from 'reactstrap';
import Header from '../components/Header';

function Terms() {
  return (
    <div className="bg_policy">
      <Header />
      <Container className="mt-3 mb-3 terms_conditions">
        <h3 className="fw-bold">TERMS OF USE </h3>
        <p>
          These terms and conditions apply to your use of: (1) the Carli website
          at <span className="text_with_background">thecarliapp.com</span> and
          all affiliated websites owned and operated solely by Carli
          Technologies Inc. (“Carli”) and (2) the tools made available by Carli
          to tenants through the Carli Site and the Carli-branded application
          for smartphone devices (collectively, the “Carli Application”), and
          any other online properties of Carli.
        </p>

        <p>
          <span className="text_with_background">
            This is a legal agreement between you and Carli, and incorporates
            the Privacy Policy available at [weblink]. By registering your use
            of the Service, you are accepting to be bound to the terms of this
            Agreement.
          </span>{' '}
        </p>
        <p>
          In these terms and conditions, “Carli”, “we”, “us” means{' '}
          <span className="text_with_background">Carli Technologies Inc.</span>{' '}
          and “you” means any person using the Carli Application or any other
          services offered by Carli.
        </p>
        <ul>
          <li className="mt-2">
            By using the Carli Application, you agree to these terms and
            conditions. If you do not accept all of these terms and conditions,
            you may not use the Carli Application. We may, at our sole
            discretion, at any time and without advance notice or liability,
            suspend, terminate or restrict your access to all or any component
            of the Carli Application if, in our sole reasonable judgment, any of
            these terms and conditions are violated. We recommend you print out
            a copy of these terms and conditions for your future reference.
          </li>
          <li className="mt-2">
            We may update or revise these terms and conditions from time to
            time. You agree that you will review these terms and conditions
            periodically. You are free to decide whether or not to accept a
            modified version of these terms and conditions, but accepting these
            terms and conditions, as modified, is required for you to continue
            using our Carli Applications. You may have to click “accept” or
            “agree” to show your acceptance of any modified version of these
            terms and conditions.
          </li>
          <li className="mt-2">
            All of the content and underlying software and other information
            used in generating and/or displaying the Carli Application is owned
            by Carli the operator of the Carli Application, or by a third party
            which has agreed to allow use by Carli of such software or other
            information. Such software or other information includes, but is not
            limited to, any trademarks, copyrights, patents, or other
            proprietary rights. You will not copy or transmit any of such
            software or other information except for your personal,
            non-commercial use on your own computer system. All copyright,
            trademark and other proprietary rights notices must be honored by
            you to the fullest extent of the law.
          </li>

          <li className="mt-2">
            You represent that you are of legal age and otherwise legally
            competent to enter into legally binding contracts. You also agree to
            be legally responsible for any use of the Carli Application that
            requires authentication through a password. Thus, maintaining
            control and confidentiality of your login and password information
            is your responsibility. If you have reason to believe your password
            has been compromised, you should change it promptly or notify Carli
            immediately. You agree that Carli may, in its discretion and upon
            such notification, suspend your account temporarily, until such time
            as, in Carli’s reasonable judgment, any threat due to the compromise
            of the password has been rectified.
          </li>

          <li className="mt-2">
            You must provide your full legal name, a valid email address, and
            any other information requested in order to complete the signup
            process. You are also responsible for keeping your email address up
            to date, so we can send you important notices.
          </li>
          <li className="mt-2">
            If you use the Carli Application to configure your account to
            receive messages to your mobile device, you must ensure your account
            with the Carli Application has the correct, current phone number and
            email address associated with that mobile device. You are
            responsible for any charges incurred to receive information on such
            mobile device. We will not be liable for information sent to a
            device that is associated with your outdated mobile phone number.
          </li>
          <li className="mt-2">
            Use of the Carli Application does not give you ownership of any
            intellectual property rights in the Carli Application or any content
            posted on the Carli Application. You own what you post on the Carli
            Application (unless you copied it from someone else) but you grant
            us a license to copy, host, display, create derivative works from,
            publish, publicly perform, display, and distribute, and otherwise
            make use of in connection with providing the Carli Application, all
            information and content you post so long as you have an active
            account.
          </li>

          <li className="mt-2">
            Your account may only be used by one person. A single account shared
            by more than one person is not permitted.
          </li>
          <li className="mt-2">
            You are responsible for the accuracy of everything that is posted or
            submitted under your account.
          </li>
          <li className="mt-2">
            We are not a real estate agent or broker. We don’t screen properties
            or offer any guaranty or advice regarding the suitability of
            properties or landlords. Nothing we say should be construed as such
            advice, and you agree not to rely on us in making decisions as to
            entering a lease for a property or otherwise.
          </li>

          <li className="mt-2">
            You may not use the Carli Application for any illegal purpose. You
            may not, in using the Carli Application, violate any laws in your
            jurisdiction or ours, or infringe the rights of anyone (ours or
            third parties). You may not use the Carli Application as part of any
            fraudulent scheme or transaction. If we have reason to suspect your
            account has been used for an unauthorized or illegal purpose, you
            agree that we may share information about you, your account, and any
            of your transactions with law enforcement.
          </li>
          <li className="mt-2">
            Through the Carli Application we allow you to engage with and book
            certain professional services from third party service providers
            <strong>(“Service Professionals”)</strong>. These services may
            include laundry services, plumbers, electricians and other related
            maintenance and in-home cleaning services. Carli does not itself
            provide any of these services. The provision of all such services is
            up to the Service Professionals, which may be scheduled through use
            of the Carli Application.
            <p>
              CARLI, THROUGH THE CARLI APPLICATION, OFFERS INFORMATION AND A
              METHOD TO OBTAIN SUCH SERVICES, BUT DOES NOT AND DOES NOT INTEND
              TO PROVIDE SUCH SERVICES ITSELF OR ACT IN ANY WAY AS A CLEANING,
              MAINTENANCE, OR OTHER HOME-RELATED SERVICE PROFESSIONAL, AND HAS
              NO RESPONSIBILITY OR LIABILITY FOR ANY SUCH SERVICES PROVIDED TO
              YOU BY ANY SUCH SERVICE PROFESSIONALS, INCLUDING, BUT NOT LIMITED
              TO, A WARRANTY OR CONDITION OF GOOD AND WORKMANLIKE SERVICES,
              WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE OR COMPLIANCE WITH
              ANY LAW, REGULATION, OR CODE. CARLI IS NOT AFFILIATED WITH,
              ENDORSED OR SPONSORED BY ANY SUCH SERVICE PROFESSIONALS.
            </p>
          </li>
          <li className="mt-2">
            You are advised to exercise caution and common sense to protect your
            personal safety and property, just as you would when interacting
            with any person whom you do not know or are not familiar with. BY
            USING THE CARLI APPLICATION, YOU AGREE TO HOLD CARLI FREE FROM THE
            RESPONSIBILITY FOR ANY LIABILITY OR DAMAGE THAT MIGHT ARISE OUT OF
            THE SERVICES PROVIDED TO YOU BY SERVICE PROFESSIONALS. CARLI IS NOT
            RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY
            SERVICE PROFESSIONALS, AND WILL NOT BE LIABLE FOR ANY CLAIM, INJURY
            OR DAMAGE ARISING TO PERSON OR PROPERTY IN CONNECTION WITH ANY
            SERVICES PROVIDED BY ANY SUCH SERVICE PROFESSIONALS.
          </li>
          <li className="mt-2">
            <span className="text_with_background">
              TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF CARLI FOR
              ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED
              WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE
              SERVICES (OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICES AGAIN).
            </span>
          </li>
          <li className="mt-2">
            THE CARLI APPLICATION IS PROVIDED “AS-IS” WITHOUT PROMISES OF ANY
            KIND. NEITHER WE NOR OUR SUPPLIERS WARRANT OR GUARANTEE UPTIME OR
            AVAILABILITY OF THE CARLI APPLICATION, THAT THE CARLI APPLICATION
            WILL MEET OR CONTINUE IN THE FUTURE TO MEET YOUR NEEDS, OR THAT WE
            WILL CONTINUE TO PROVIDE THE CARLI APPLICATION OR ANY ASPECT OF THE
            CARLI APPLICATION IN THE FUTURE. TO THE MAXIMUM EXTENT PERMITTED BY
            LAW, WE DISCLAIM ALL WARRANTIES FOR THE CARLI APPLICATION, INCLUDING
            WITHOUT LIMITATION ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ANY AND ALL
            WARRANTIES ARISING FROM COURSE OF DEALING AND USAGE OF TRADE; AND
            THAT THE APP WILL MEET THE PROPERTY MANAGER’S REQUIREMENTS, WILL
            ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, OR
            OPERATE WITHOUT ERROR.
          </li>
          <li className="mt-2">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE OR
            RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES,
            OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.{' '}
          </li>
          <li className="mt-2">
            You understand and agree that the disclaimers, exclusions, and
            limitations set forth herein are essential elements of these terms
            and conditions and that they represent a reasonable allocation of
            risk. In particular, the Carli Application is available to you
            subject to the terms and conditions herein, and these terms and
            conditions will survive and apply even if any limited remedy
            specified in these terms and conditions is found to have failed its
            essential purpose.
          </li>
          <li className="mt-2">
            <span className="text_with_background">
              You will indemnify us, defend us, and hold us harmless from and
              against any and all claims, losses, causes of action, demands,
              liabilities, costs or expenses (including, but not limited to,
              litigation and reasonable attorneys' fees and expenses) arising
              out of or relating to any of the following: (a) your access to or
              use of the Carli App; (b) any actions made with your account
              whether by you or by someone else; (c) your violation of any of
              the provisions of these Terms and Conditions ; (d) non-payment for
              any of the services which were provided through the Carli
              Application; (e) your violation of any third party right,
              including, without limitation, any intellectual property right,
              publicity, confidentiality, property or privacy right.
            </span>
          </li>
          <li className="mt-2">
            <span className="text_with_background">
              Carli shall not be held liable to and shall not accept any
              liability, obligation or responsibility whatsoever for any loss or
              damage in connection with any action or inaction by the strata. We
              have no control over the strata and we are not responsible for
              their actions.{' '}
            </span>
          </li>
          <li className="mt-2">
            <span className="text_with_background">
              You acknowledge that neither Carli nor any of its agents,
              employees or representatives has made any representations or
              warranties, either written or oral, concerning the security and
              safety of the use of the Services and the confidentiality of any
              information provided by you to product or service providers on the
              Carli Application. You acknowledge that Carli neither warrants nor
              guarantees your safety against any criminal or wrongful acts of
              third parties. You are responsible for protecting your own
              respective person and property and hereby release Carli and its
              agents, employees and representatives for any and all damage to
              person and property. Carli’s safety measures are neither a
              warranty of safety nor a guaranty against crime or of a reduced
              risk of crime.{' '}
            </span>
          </li>
          <li className="mt-2">
            If any of the provisions, or portions thereof, of these terms and
            conditions are found to be invalid under any applicable statute or
            rule of law, then, that provision (or portion thereof)
            notwithstanding, these terms and conditions shall remain in full
            force and effect and such provision or portion thereof shall be
            deemed omitted.
          </li>
          <li className="mt-2">
            These terms and conditions and the rights granted and obligations
            undertaken hereunder may not be transferred, assigned, or delegated
            in any manner by you, but may be freely transferred, assigned, or
            delegated by us.
          </li>
          <li className="mt-2">
            These terms and conditions made under and shall be governed by and
            construed in accordance with the laws of the Province of British
            Columbia, without giving effect to any principles that provide for
            the application of the law of another jurisdiction.{' '}
          </li>
          <li className="mt-2">
            <span className="text_with_background">
              You agree to (1) submit to the personal jurisdiction of courts in
              the Province of British Columbia.
            </span>
          </li>
          <li className="mt-2">
            <span className="text_with_background">
              To clear any doubt, all clauses regarding limitations of
              liabilities and indemnification shall survive the termination or
              expiration of these Terms and Conditions.
            </span>
          </li>
          <li className="mt-2">
            <span className="text_with_background">
              The Carli Application depends on various factors such as software,
              hardware and tools, either our own or those owned and/or operated
              by our contractors and suppliers. While we make commercially
              reasonable efforts to ensure the Carli Application’s reliability
              and accessibility, you understand and agree that no
              website/application can be 100% reliable and accessible and so we
              cannot guarantee that access to the Carli Application will be
              uninterrupted or that it will be accessible, consistent, timely or
              error-free at all times.
            </span>
          </li>
          <li className="mt-2">
            <span className="text_with_background">
              Unless you have entered into a separate written or electronic
              agreement with us that expressly references the Carli Application,
              this is the exclusive and entire agreement between us and you, and
              supersedes all prior and contemporaneous understandings,
              agreements, representations, and warranties, both written and
              oral.
            </span>
          </li>
        </ul>
      </Container>
    </div>
  );
}

export default Terms;
